<template lang="pug">
div#me
  div.banner.rel: img(src="@/assets/img/mepic.png")/
  div.me-center.rel.mod
    div.avatar
      img.center(:src="user.avatarurl || require('@/assets/img/mi.jpg')")/
      div.center
        p.rel.padding._10_0(@click="_showModal('userBox')")
          | {{user.nickname || '昵称'}}
          img.abs.edit(src="@/assets/img/edit.png")/
    div.team.flex.small.margin._10_0(@click="_modelAction('teamBox')")
      img.ico(src="@/assets/img/team.png")
      p.margin._0_10 我所在的团队
      p.txt.red {{user.team_name || '尚未加入团队'}}
    div.me-navs
      div.tit.center.small.padding._5 个人信息
      ul.flex
        li.center.small(v-for="(n,i) in nav" :key="i" @click="_modelAction(n.key)")
          img(:src="require(`@/assets/img/me_${i}.png`)")/
          p {{n.title}}
    userBox(title="修改个人信息" :closeIco="true" height="12rem" :show="show.userBox" bgcolor="#FFBA00" @close="_showModal('userBox')")
      div.user-box.center.txt.white
        div.flex
          label 昵称：
          p.padding._5: input(placeholder="输入昵称" ref="nickname")/
        div: span.btn(@click="_nickname") 确 认
    teamBox(title="加入团队" :closeIco="true" height="12rem" :show="show.teamBox" bgcolor="#FFBA00" @close="_showModal('teamBox')")
      div.team-box.center.txt.white
        div.flex
          label 选择团队：
          p.padding._5: select(ref="pickTeam")
           option(v-for="t,i in box.teamList" :value="t.tid") {{t.team_name}}
        div: span.btn(@click="_pickTeam") 确 认

    topBox(title="排行榜" :closeIco="true" height="50vh" :show="show.topBox" bgcolor="#fff" @close="_showModal('topBox')")
      div.top-box.mod
        div.tabs.flex.center.txt.red.small
          div.tab.grow(v-for="n,i in tab.items" :class="{active: tab.i == i}" @click="_tab(i)") {{n.title}}
        div.table-box
          table.small.txt.grey
            tbody
              tr(v-for="n,i in top.list")
                td {{i + 1 + (page - 1) * 10}}<!-- 这里的10必须和服务器limit保持一致-->
                td {{n.team_name}}
                td {{Number(n.avg_credit || n.credit)}}
        p.pagination.center.small(v-if="top.totalnum > 1")
          span(v-for="n in top.totalnum" @click="_tab(tab.i, n)" :class='{active: page == n}') {{n}}

    creditBox(title="积分明细" :closeIco="true" height="40vh" :show="show.creditBox" bgcolor="#fff" @close="_showModal('creditBox')")
      div.credit-box
        table
          thead.txt.red.small <tr><th>日期</th><th>积分来源</th><th>分值</th></tr>
        div.table-box(@scroll="_creditBoxScroll")
          table
            tbody.center.small.x
              tr(v-for="n in credit.list")
                td {{n.crcreate_date}}
                td {{n.comefrom}}
                td {{n.credit}}
    aboutBox(title="关于" :closeIco="true" height="40vh" :show="show.aboutBox" bgcolor="#fff" @close="_showModal('aboutBox')")
      div.about-box.padding._10.small(v-html="about")
    tipBox(bgcolor="rgba(0,0,0,.7)" height="7rem" width="7rem" :show="show.tipBox") <!--提示框-->
      div.flex.center.tip-box: p.txt.white.grow {{box.tip}}
</template>
<script>
import model from '@/components/modal/index';
import user from '@/assets/script/cache'
export default {
  components: {userBox: model, teamBox: model, topBox: model, creditBox: model, aboutBox: model, tipBox: model},
  data(){return{
    nav: [{title: '排名详情', key:"topBox"}, {title: '积分明细', key:"creditBox"}, {title: '关于', key:"aboutBox"}],
    show: {userBox: false, teamBox: false, topBox: false, creditBox: false, aboutBox: false, tipBox: false},
    tab: {items: [{key: '', title:'团队排名'}, {key: '', title:'队友排名'}, {key: '', title:'全员排名'}], i: 0, objs: []},
    user: {},
    top: [], about: {}, credit: [], page: 1, creditpage: 1,
    box: {
      teamlist: [],
      tip: '操作成功'
    },
  }},
  methods: {
    _modelAction(key){
      let promise = {}
      switch(key){
        case 'teamBox':
          promise = this.getList('teamList').then(res=>{
            this.box.teamList = res
          })
        break; case 'creditBox':
          promise = this.getList('meCredit').then(res=>{
            this.credit = res
          })
        break; case 'topBox':
          promise = this._tab(this.tab.i)
        break; case 'aboutBox':
          promise = this.getList('meAbout').then(res=>{
            this.about = res.about
          })
        break; default:
          return this._showModal(key)
      }
      promise.then(()=>{
        this._showModal(key)
      })
    },
    _showModal(key){
      this.show[key] = !this.show[key]
    },
    _showTip(duration = 700){
      this._showModal('tipBox')
      setTimeout(()=>{
        this._showModal('tipBox')
      }, duration)
    },
    _tab(i, page = 1){
      this.tab.i = i
      this.page = page
      let uri = ['meTopTeams', 'meTopTeam', 'meTopTeams'][i]
      return new Promise((resolve, reject)=>{
        this.getList(uri, page).then(res=>{
          this.top = res
          resolve()
        })
      })
    },
    _pickTeam(){
      let {value: tid, selectedIndex: i} = this.$refs.pickTeam
      let {team_name} = this.box.teamList[i]
      this.$req.teamJoin({tid}, ()=>{
        this._showTip()
        user.set('team_id', tid).set('team_name', team_name)
        this.user.team_id = tid
        this.user.team_name = team_name
        this._showModal('teamBox')
      })
    },
    _nickname(){
      let nickname = this.$refs.nickname.value
      this.$req.userSet({nickname}, ()=>{
        this._showTip()
        user.set('nickname', nickname)
        this.user.nickname = nickname
        this._showModal('userBox')
      })
    },
    _creditBoxScroll(e){// 下拉刷新
      if(this.creditpage > 0){
        let {scrollTop, offsetHeight} = e.target
        let a_height = offsetHeight / 8 // n与css保持一致
        let full_height = this.credit.list.length * a_height // 总高度
        if((full_height - offsetHeight) - scrollTop <= 3){
          setTimeout(()=>{
            this.getList('meCredit', ++this.creditpage).then(res=>{
              if(res.list.length == 0){
                this.creditpage = -1
              }else{
                this.credit.list = this.credit.list.concat(res.list)
              }
            })
          }, 700)
        }
      }
    },
    showBox(){
      let {box} = this.$route.query
      if(box){
        this._modelAction(`${box}Box`)
      }
    },
    getList(uriFunc, page = 1){
      return new Promise((resolve, reject)=>{
        this.$req[uriFunc]({page}, (res)=>{
          resolve(res)
        })
      })
    },
  },
  mounted(){
    this.showBox()
    this.user = user.get()
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/func.less";
#me{
  .banner{
    z-index: 1;
    img{width: 100%;};
  }
  .table-box{
    height: calc((var(--fontsize) + 0.625rem) * 8);// n表示几行
    overflow-y: scroll;
  }
  table{
    border-collapse: collapse;
    width: 100%;
    td{
      .__spacing(padding, 5, 3);
    }
  }
  .me-center{
    z-index: 2;
    .__rem(-75, top);
    .avatar{
      img{
        &.center{
          border-radius: 50%;
          border-color: rgba(255,255,255,.8);
          border-style: solid;
          box-shadow: 0 3px 5px rgba(0,0,0,.1);
          .__wh(100, 100);
          .__bg($border-color);
          .__rem(4, border-width);
        }
        &.edit{
          .__wh(20, 20.5);
          .__rem(5, margin-left)
        }
      }
    }
    .team{
      .ico{.__wh(24, 17);}
    }
    .me-navs{
      .tit{
        .__bg(#86CAFF, #065795);
        .__rem(5, border-radius);
      }
      ul{
        flex-wrap: wrap;
        li{
          width: 50%;
          .__rem(15, padding-top);
          img{
            //236 212
            width: 50%;
          }
        }
      }
    }
  }
  span.btn{
    .__rem(24, word-spacing);
    text-decoration: underline;
  }
  .user-box,.team-box{
    .flex{
      display: inline-flex;
      .__spacing(padding, 30);
      p{
        .__bg(#fff);
        .__rem(5, border-radius);
        input{
          border:none;
        }
      }
    }
  }
  .team-box{
    select{
      border:none;
      background: #fff;
      .__rem(160, width);
    }
  }
  .top-box{
    .tab{
      .__rem(2, padding-bottom);
      .__border(bottom, #ddd, solid, 2);
      &.active{
        border-color: var(--red);
      }
    }
    .table-box{
      height: calc((var(--fontsize) + 0.625rem) * 10);// n表示几行
    }
    .pagination span{
      display: inline-block; border-radius: 50%;
      .__wh(20, 20); .__rem(20, line-height);
      .__spacing(margin, 5);
      &.active{
        .__bg(var(--red), #fff);
      }
    }
  }
  .credit-box thead th{
    .__border(bottom, var(--red));
    .__spacing(padding, 5, 3);
  }
  .about-box{
    line-height: 1.6;
  }
  .tip-box{
    height: 7rem;
  }
}
</style>
<style scoped>
.about-box >>> p{
  text-indent: 2em;
}
</style>
